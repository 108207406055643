var body = document.body

//Hamburger Menu Button
document.addEventListener('DOMContentLoaded', function() {

  var toggleBtn = document.querySelector('.cpa-nav__button')
  var hamburgerIcon = document.querySelector('.cpa-nav__button--hamburger')

  if(toggleBtn) {
    toggleBtn.addEventListener('click', function() {
      body.classList.toggle('cpa-nav--active')
      hamburgerIcon.classList.toggle('active')
    })
  }

  var menuItemHasChildren = document.querySelectorAll('.menu-item-has-children')

  for (var i = 0; i < menuItemHasChildren.length; i++) {

    menuItemHasChildren[i].addEventListener('mouseenter', function(e) {

      if (!body.classList.contains('cpa-nav--active')) {
        
        e.target.classList.add('current-menu-item')

      } 
    })

    menuItemHasChildren[i].addEventListener('mouseleave', function(e) {

      if (!body.classList.contains('cpa-nav--active')) {
      
        e.target.classList.remove('current-menu-item')

      } 
    })
    
    menuItemHasChildren[i].onclick = function(e) {

      if (body.classList.contains('cpa-nav--active')) {

        e.stopPropagation()
        e.target.parentElement.classList.toggle('current-menu-item')
        
      }
    }
  }

  menuAccessibilityAndFunctionality(document.querySelectorAll('.cpa-nav--primary .cpa-nav__menu'));
  menuAccessibilityAndFunctionality(document.querySelectorAll('.cpa-nav--secondary .cpa-nav__menu'));

});

function menuAccessibilityAndFunctionality(navigation){

  Array.prototype.slice.call(navigation).forEach(function (menuElements) {

    var allowMultiple = menuElements.hasAttribute('data-allow-multiple');
    var allowToggle = true

    menuElements.addEventListener('click', function (event) {
      var target = event.target;

      if (target.nodeName === 'A') {
        target = target.parentElement
      }

      if(target.classList.contains('cpa-nav--has-children') && target.children[0].href == '#'){
        event.preventDefault();
      }

      if (target.classList.contains('cpa-nav--has-children')) {

        var isExpanded = target.children[0].getAttribute('aria-expanded') === 'true';
        var active = menuElements.querySelector('[aria-expanded="true"]');

        if (!allowMultiple && active && active !== target.children[0]) {
          active.setAttribute('aria-expanded', 'false');
          target.classList.remove('active');

          if (!allowToggle) {
            active.removeAttribute('aria-disabled');
          } 
        }

        if (!isExpanded) {
          target.children[0].setAttribute('aria-expanded', 'true');
          target.classList.add('active');

          if (!allowToggle) {
            target.children[0].setAttribute('aria-disabled', 'true');
          }

        } else if (allowToggle && isExpanded) {
          target.children[0].setAttribute('aria-expanded', 'false');
          target.classList.remove('active');
        }
      }
    });
  })
}